import client from '@/services/';
import { saveAs } from 'file-saver';


const state = () => ({
    all: [],
    one: {},
});

const getters = {
    all: state => state.all,
    one: state => state.one
};

const mutations = {
    SET_ALL: (state, obj) => {
        state.all = obj;
    },
    SET_ONE: (state, obj) => {
        state.one = obj;
    },
};

const actions = {
    async getAllP(cxt, payload = {page: 1, texte: '', size: 7, regionId: '', startDate: '', endDate: '', communeId: '', formeJuridiqueId: '', /*produitId: '',*/ raisonSociale: ''},) {
        let { data } = payload.texte !== '' ? await client.get('requete/membre/'+payload.page, {params: {texte: payload.texte}}) : await client.get('requete/membre/'+payload.page+'/'+payload.size+'/true' , {params: {regionId: payload.regionId, startDate: payload.startDate, endDate: payload.endDate, communeId: payload.communeId, formeJuridiqueId: payload.formeJuridiqueId/*, produitId: payload.produitId*/}});
        cxt.commit("SET_ALL", {
            loaded: true, ...data, number: data.number+1,
            content: data.content || []
        })
        //cxt.commit("SET_ALL", data);
        return data;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};